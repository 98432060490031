import React from 'react'
import { Link } from 'gatsby'
import '../components/notfound.css'

const NotFoundPage = () => (
  <div id="notfound">
    <div class="notfound">
      <div class="notfound-404">
        <h1>
          4<span></span>4
        </h1>
      </div>
      <h2>Oops! Strona nie została znaleziona</h2>
      <p>
        Przepraszamy, prawdopodobnie wskazana podstrona nie istnieje, bądź nie
        jest dostępna
      </p>
      <Link to="/">Wróć na stronę</Link>
    </div>
  </div>
)

export default NotFoundPage
